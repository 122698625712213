<template>
    <v-snackbar
        v-model="hideShowSnackbar"
        :bottom="getPosX === 'bottom'"
        :top="getPosX === 'top'"
        :left="getPosX === 'left'"
        :right="getPosX === 'right'"
        :vertical="getMode === 'vertical'"
        :multi-line="getMode === 'multi-line'"
        :timeout="getTimeout"
    >
        {{ getSnackbarMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="hide">
                {{ getActionButton }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as snackbarTypes from "../../store/actions/snackbar";

export default {
    computed: {
        ...mapGetters({
            hideShowSnackbar: snackbarTypes.SNACKBAR_GET_STATUS,
            getSnackbarMessage: snackbarTypes.SNACKBAR_GET_MESSAGE,
            getActionButton: snackbarTypes.SNACKBAR_GET_ACTION_BUTTON,
            getMode: snackbarTypes.SNACKBAR_GET_MODE,
            getPosX: snackbarTypes.SNACKBAR_GET_X,
            getPosY: snackbarTypes.SNACKBAR_GET_Y,
            getTimeout: snackbarTypes.SNACKBAR_GET_TIMEOUT
        })
    },
    methods: {
        ...mapActions({
            hideSnackbar: snackbarTypes.SNACKBAR_HIDE
        }),
        hide() {
            this.hideSnackbar();
        }
    }
};
</script>

<style></style>
